var render = function () {
  var this$1 = this
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    [
      this.isFirstLoading
        ? _c(
            "v-row",
            [
              _c(
                "v-col",
                { staticClass: "mx-auto", attrs: { cols: "12", sm: "6" } },
                [
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "primary" },
                      on: {
                        click: function () {
                          this$1.$router.go(-1)
                        },
                      },
                    },
                    [_c("v-icon", [_vm._v(" mdi-arrow-left-bold ")])],
                    1
                  ),
                  _c(
                    "v-toolbar",
                    { staticClass: "secondary white--text text-h5 mt-3" },
                    [_c("span", [_vm._v(" Proof of payment form ")])]
                  ),
                  _c("AError", { attrs: { api: this.api } }),
                  this.$store.getters.getRole == "salesperson"
                    ? _c(
                        "v-card",
                        [
                          _c("v-container", [
                            _c(
                              "div",
                              { staticClass: "d-flex flex-column" },
                              [
                                _c(
                                  "p",
                                  {
                                    staticClass:
                                      "d-flex align-content-center flex-wrap",
                                  },
                                  [_vm._v("Upload attachment")]
                                ),
                                _c("v-file-input", {
                                  attrs: {
                                    color: "primary",
                                    outlined: "",
                                    multiple: "",
                                    label: "Please choose the files",
                                    counter: "",
                                    "show-size": 1000,
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "selection",
                                        fn: function (ref) {
                                          var index = ref.index
                                          var text = ref.text
                                          return [
                                            index < 2
                                              ? _c(
                                                  "v-chip",
                                                  {
                                                    attrs: {
                                                      color:
                                                        "deep-purple accent-4",
                                                      dark: "",
                                                      label: "",
                                                      small: "",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " + _vm._s(text) + " "
                                                    ),
                                                  ]
                                                )
                                              : index === 2
                                              ? _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "text-overline grey--text text--darken-3",
                                                  },
                                                  [
                                                    _vm._v(
                                                      " +" +
                                                        _vm._s(
                                                          _vm.files.length - 2
                                                        ) +
                                                        " Files(s) "
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    false,
                                    4191225506
                                  ),
                                  model: {
                                    value: _vm.attachments,
                                    callback: function ($$v) {
                                      _vm.attachments = $$v
                                    },
                                    expression: "attachments",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "d-flex justify-end" },
                              [
                                _c(
                                  "v-btn",
                                  {
                                    attrs: { color: "primary" },
                                    on: { click: _vm.submit },
                                  },
                                  [_vm._v(" Upload ")]
                                ),
                              ],
                              1
                            ),
                          ]),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }